const Services = () => {
    return (
      <section id="services" className="bottom-space" >
        <div className="container  d-flex flex-column align-items-center" >
             <h2 className="header">Our Services</h2>
             <hr className="small-line" />
            {/* <div className="row justify-content-center mb-5">
                  <p className="Large col-8 text-center">We empower businesses to harness the full potential of their data and cloud infrastructure. Specializing in Big Data, Data integration, FinOps and Cloud solutions, we deliver tailored strategies and cutting-edge technologies to help you drive efficiency, optimize costs, and scale with confidence.</p>
            </div>
       */}
      <div className="row justify-content-center"> 
        <div className="col-lg-3 d-flex flex-column align-items-center text-center"> <i className="fa fa-cubes fa-3x" />
          <h3 className='service-item'>Data infrastructure</h3>
          <p>Leverage the latest in database technologies and architectures to manage large volumes of data efficiently.</p>
        </div>
        <div className="col-lg-3 d-flex flex-column align-items-center text-center"> <i className="fa fa-solid fa-cloud fa-3x" /> 
           <h3 className='service-item'>Cloud Services</h3>
          <p>We help you gain visibility into your cloud costs, implement cost-saving strategies, and ensure that your cloud investments align with your business goals.</p>
        </div>
        <div className="col-lg-3 d-flex flex-column align-items-center text-center"> <i className="fa fa-solid fa-magnifying-glass-chart fa-3x" />
          <h3 className='service-item'>Business Intelligence</h3>
          <p>Make informed decisions with real-time analytics, customized dashboards, and reporting tools that align with your business goals.</p>
        </div>
        
        <div className="col-lg-3 d-flex flex-column align-items-center text-center"> <i className="fa fa-solid  fa-file-contract fa-3x" />
          <h3 className='service-item'>Data Audit</h3>
          <p>Auditing and enhancing your infrastructure, uncovering hidden opportunities, and transforming your data into tangible value that drives efficiency, security, and growth.</p>
          {/* <p>Seamlessly connect and  unify your data sources. We specialize in integrating diverse data systems, ensuring that your data is accessible, accurate, and ready to drive business outcomes.</p> */}
        </div>
            </div>
        </div>
      </section>
    );
  };
  export default Services