import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';

const Contact = () => {
  const form = useRef();
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_15t7e2h', 'template_m70n4q8', form.current, {
        publicKey: 'PpBGdXDPiitHihca4',
      })
      .then(
        () => {
          setAlertMessage('Your message was sent successfully!');
          setAlertType('success');
          setShowAlert(true);
          form.current.reset(); // Clear form fields
        },
        (error) => {
          setAlertMessage('Failed to send your message. Please try again.');
          setAlertType('error');
          setShowAlert(true);
        },
      );
  };

  return (
    <section id="contact" className="bottom-space" name="contact">
      <div className="container d-flex flex-column align-items-center">
        <h2 className="header">Get In Touch</h2>
        <hr className="small-line" />

        <div className="col-md-7">
          <p>We're here to help you with your IT needs. Whether you have questions about our services, need technical support, or want to discuss a project, feel free to reach out.</p>
          <p>Have a specific project in mind? Fill out the form below, and we will get back to you within 24 hours.</p>

          {showAlert && (
            <div className={`alert alert-${alertType === 'success' ? 'success' : 'danger'} alert-dismissible fade show`} role="alert">
              {alertMessage}
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowAlert(false)}></button>
            </div>
          )}

          <form id="contact" method="post" className="form" role="form" ref={form} onSubmit={sendEmail}>
            <div className="row">
              <div className="col-md-12 form-group mb-3">
                <input className="form-control" id="name" name="name" placeholder="Name" type="text" required />
              </div>
              <div className="col-md-12 form-group mb-3">
                <input className="form-control" id="email" name="email" placeholder="Email" type="email" required />
              </div>
              <div className="col-md-12 form-group mb-3">
                <textarea className="form-control" id="message" name="message" placeholder="Message" rows={5} defaultValue={""} />
              </div>
              <div className="col-md-12 d-flex justify-content-center">
                <button className="btn btn-lg btn-secondary" type="submit">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
