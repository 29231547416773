// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import './App.css';
// import About from './pages/About';
// import Portfolio from './pages/Portfolio';
// import Contact from './pages/Contact';
import Header from './components/Header';
import About from './components/aboutus';
import Contact from './components/contact';
import Clients from './components/clients';
import Teams from './components/teams';
import Testimonials from './components/testimonials';
import Services from './components/services';
import Footer from './components/Footer';
import Faq from './components/faq';
function App() {
    return (
      <div>
      <Header/>
      <Home/>
      <About/>
      <Services/>
      <Clients/>
      {/* <Teams/> */}
      <Faq/>
      <Testimonials/>
      <Contact/>
      <Footer/>
    </div>

        // <Router>
        //     <Routes>
        //         <Route path="/" element={<Layout />}>
        //             <Route path='aboutus' element={<Home />} />
        //             {/* <Route path="about" element={<About />} />
        //             <Route path="portfolio" element={<Portfolio />} />
        //             <Route path="contact" element={<Contact />} /> */}
        //         </Route>
        //     </Routes>
        // </Router>
    );
}

export default App;
