import React from 'react';

import { 
    Col,
    Card
 } from "react-bootstrap";


const TestimonialCard = ({data}) => {
    return ( 
        <Col lg="8">
            <div className='row'>
            <div className="col-4  mb-5">
              <img
                className="border border-secondary rounded-circle"
                src={data.image} width="100%" height="auto"
                alt="profilepicture"
        
              />
              <div className="small mt-2 text-center">   
               {data.name} <br/>  {data.role} <br/> {data.company}
                </div>
          
          </div>
          <div className="col-8 align-text-top">
          <i className="fas fa-quote-left fa-xs text-primary mr-2"></i>
            {data.text} 
            <i className="fas fa-quote-left fa-xs text-primary ml-2"></i>
            <br/><br/>
            <figcaption className="text-right blockquote-footer mb-0">
            {data.name}, {data.company}
              </figcaption>
               
            </div>
            </div>

{/*             <div className="pb-5 text-center">
                <img className=" bg-white mb-3" src={data.companylogo} width="250" height="auto" alt=""/>
                <p className="lead">
                    {data.role}
                    <br/>
                    {data.date}
                </p>
                
            </div> */}
        </Col>
     );
}
 
export default TestimonialCard;