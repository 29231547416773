import React from 'react';

import { 
    Col,
    Card
 } from "react-bootstrap";


const TeamCard = ({data}) => {
    return ( 
      <div className='row d-flex flex-column align-items-center'>
    <div className="mb-5 position-relative">
        <img
            className="border border-secondary"
            src={data.image} 
            width="100%" 
            height="auto"
            alt="profilepicture"
        />
        <div className="position-absolute icon-container d-flex justify-content-center">
            <a href={data.linkedin} target="_blank" rel="noopener noreferrer" className="icon mx-2">
                <i className="fab fa-linkedin"></i>
            </a>
            <a href={`mailto:${data.email}`} className="icon mx-2">
                <i className="fas fa-envelope"></i>
            </a>
        </div>
        <h5 className="mt-2 text-center">
            {data.name} <br/> {data.role}
        </h5>
    </div>
</div>

     );
}
 
export default TeamCard;