import TeamCard from "../cards/teams_card";

const data = {
  name: 'Mohamed KHEY',
  role: 'CTO',
  linkedin: 'https://www.linkedin.com/in/mohamed-khey/',
  email: 'mkhey@mk4tech.fr',
  image: '/assets/photo_mohamed.jpg',
};

const About = () => {
  return (
    <section id="aboutus" style={{ padding: '50px', backgroundColor: '#e0e0e0' }}>
      <div className="container d-flex flex-column align-items-center">
        <h2 className="header">About Us</h2>
        <hr className="small-line" />
        <div className="row d-flex align-items-center"> {/* Add align-items-center to vertically align */}
          <div className="col-12 col-md-4 about-us-image">
            <TeamCard key="1" data={data} />
          </div>
          <div className="col-12 col-md-8">
            <p className="section-about-us">
              We have extensive experience in data engineering, solution integration, data modeling,
              analytics, machine learning and data science. Our work spans from leading executive
              discussions on integrating machine learning and data science teams, to developing
              comprehensive Python and R data science suites that automate significant parts of the process.
            </p>
            <p className="section-about-us">
              With our deep knowledge of data modeling, we've consistently made a meaningful impact
              on the companies we work with, whether as employees or consultants. Many of our former
              colleagues often reach out after transitioning to new roles or projects, seeking our
              expertise to design or lead new initiatives.
            </p>
            <p className="section-about-us">
              Mohamed, our CTO, specializes in turning complex data into actionable insights that drive profitability. 
              Offering deep expertise in data analytics, big data architecture, data governance and machine learning, 
              he provides tailored solutions for both startups and established enterprises.
            </p>
            <p className="section-about-us">
              By focusing on customized strategies, sustainable systems, and transparent collaboration, Mohamed ensures 
              long-term success for your business. From workshops and training to ongoing support, 
              he empowers your team to manage and expand data solutions. 
              
            </p>
            <p className="section-about-us">
            Ready to transform your data into a competitive advantage? Let’s connect.
              </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
