// src/pages/Home.js
import React from 'react';
import { motion } from 'framer-motion';


const Home = () => {
    return (
<section id="home">
    <div
        className='home-bg'
        style={{
            background: "url('/assets/header_1.jpg') no-repeat center top",
            backgroundSize: 'cover',
            minHeight: '750px',
            width: '100%',
            position: 'relative'
        }}
    >
        <div className="overlay-text">

            Turning Data into Decisions<br/>
            Excellence into Results
        <a className="services-button" href="https://calendly.com/mkhey-mk4tech/30min" >Book a Free Consultation</a>
        </div>
    </div>
</section>
    );
  };

  export default Home;
