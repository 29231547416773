import { Row } from "react-bootstrap";

const LogoRibbon = () => {
  const logos = [
      { id: 1, src: '/assets/raiseyour1.png', alt: 'Raize Your Biz ' },
      { id: 2, src: '/assets/soge.png', alt: 'Societe generale' },
      { id: 3, src: '/assets/astek.png', alt: 'Astek' },
      { id: 3, src: '/assets/caas1.png', alt: 'Crédit agricole Assurance' },
      { id: 3, src: '/assets/equancy.png', alt: 'Equancy' },
      { id: 3, src: '/assets/orange.png', alt: 'Orange' },
      { id: 3, src: '/assets/vinci.png', alt: 'Vinci' },
      { id: 3, src: '/assets/talan.png', alt: 'Talan' },
            // Add more logos here
  ];
  return (
<div className="logo-ribbon">
    <div className="logo-track">
        {logos.map((logo, index) => (
            <div 
                key={index} 
                className="logo-container" 
                style={{ marginRight: index == logos.length - 1 ? '0' : '15em' }} // Adjust the value as needed
            >
                <img src={logo.src} alt={logo.alt} className="logo-image" />
            </div>
        ))}
    </div>
</div>
);
};

const Clients = () => {
    return (
      <section id="clients"  className="bottom-space" style={{backgroundColor: '#e0e0e0' }}>
        <div className="container  d-flex flex-column align-items-center">
             <h2 className="header">Our Clients</h2>
             <hr className="small-line" />

         <LogoRibbon/>  
        </div>
      </section>
    );
  };
  export default Clients