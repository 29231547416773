import React, { useState } from 'react';


const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What services does your company offer?",
      answer: "We specialize in Big Data solutions, cloud data architecture, data integration, business intelligence, and FinOps. Our services include cloud migration, data pipeline development, analytics, machine learning integration, and cost optimization strategies for cloud environments."
    },
    {
      question: "What cloud platforms and tools do you work with?",
      answer: "We work with leading cloud platforms such as AWS, Microsoft Azure, and Google Cloud. Additionally, we are proficient in tools like Hadoop, Apache Spark, Kafka, Snowflake, and popular analytics platforms like Power BI, Tableau, and Looker."
    },
    {
      question: "How can you help us optimize cloud costs (FinOps)?",
      answer: "We apply FinOps best practices to ensure your cloud usage is cost-efficient. We offer services like cloud cost visibility, resource optimization, and automation to help you manage and reduce cloud expenditures without sacrificing performance."
    },
    {
      question: "How do you ensure data security and compliance?",
      answer: "Data security is a priority. We follow industry standards to ensure data privacy and compliance with regulations such as GDPR, HIPAA, and others. Our approach includes encryption, secure access controls, and ongoing security audits."
    },
    {
      question: "Can you help us migrate to the cloud?",
      answer: "Yes! We offer end-to-end cloud migration services, including assessment, planning, and execution. We ensure a seamless transition while minimizing downtime and disruption to your business operations."
    },
    {
      question: "What industries do you typically work with?",
      answer: "We have experience across various industries, including finance, healthcare, retail, and telecommunications. Our solutions are tailored to meet the unique needs of each industry."
    },
    {
      question: "How long does a typical project take?",
      answer: "Project timelines vary depending on complexity and scope. A typical engagement can range from a few weeks for smaller projects to several months for larger-scale implementations. We provide a detailed project plan during our initial assessment."
    },
    {
      question: "How do you handle data integration across different sources?",
      answer: "We use advanced data integration techniques to consolidate data from various sources, ensuring a unified, accurate, and consistent data flow across your systems. Our team is skilled in ETL processes and real-time data stream management."
    },
    {
      question: "Can you provide examples of past projects or case studies?",
      answer: "Yes, we have several case studies showcasing successful projects for clients in various industries. Contact us for more details, and we’ll be happy to share examples relevant to your needs."
    },
    {
      question: "How is your pricing structured?",
      answer: "Our pricing depends on the scope and duration of the project. We offer flexible options, including time-based (TJM), project-based, and ongoing support contracts. Contact us for a customized quote."
    },
  ];

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };


    return (
      <section id="faq" style={{ padding: '50px', }} >
        <div className="container d-flex flex-column align-items-center">
             <h2 className="header">Frequently Asked Questions</h2>
             <hr className="small-line" />
             <p className='section-about-us'>If you have questions about our data analytics and data science consulting services, explore the following FAQs for guidance.</p>
             </div>
             <div className="faq-container">

             <div className="faq-list">
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div className="faq-question" onClick={() => toggleFAQ(index)}>
                      {faq.question}
                      <span className={`faq-arrow ${activeIndex === index ? 'active' : ''}`}>▼</span>
                    </div>
                    {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
                  </div>
                ))}
              </div></div>
        
      </section>
    );
  };
  export default Faq