// src/components/Header.js
import React from 'react';
import styled from 'styled-components';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';

function Header() {
  return (
    <Navbar bg="light" variant="light" expand="lg" sticky="top">
    <div className="container"> 
      <Navbar.Brand href="#home" className='mr-5'>
        <img
          src="/assets/logo.png"
          height="45px"
          className="d-inline-block align-top"
          alt="Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
        <Nav className="mc-auto">
          <Nav.Link href="#home" className='me-3'>Home</Nav.Link>
          <Nav.Link href="#aboutus" className='me-3'>About Us</Nav.Link>
          <Nav.Link href="#services" className='me-3'>Services</Nav.Link>
          <Nav.Link href="#clients" className='me-3'>Clients</Nav.Link>
          <Nav.Link href="#faq" className='me-3'>FAQ</Nav.Link>
          {/* <Nav.Link href="#teams    " className='me-3'>Team</Nav.Link> */}
          <Nav.Link href="#testimonials" className='me-3'>Testimonials</Nav.Link>
          <Nav.Link href="#contact" className='me-3'>Get In Touch</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Header;
