import React from "react";



const icons =[

  {
    image: "fa-linkedin",
    url: "https://www.linkedin.com/in/mohamed-khey/",
  } ,    {
    image: "fa-github",
    url: "https://github.com/med-khey",
  },   {
    image: "fa-medium",
    url: "https://medium.com/@moha-khey",
  },   {
    image: "fa-twitter",
    url: "https://twitter.com/KheyMohamed",
  }
];



const Footer = () => {
  const bgStyle = { backgroundColor: "#f5f5f5" };

  return (
    <footer className="footer-20192">
      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center">
              <h1 className="brand"><a href="#" >
              <img
          src="/assets/logo blanc.png"
          height="45px"
          className="d-inline-block align-top"
          alt="Logo"
        />
                </a></h1>
              <p className="">Turning Data into Decisions, <br/> Excellence into Results</p>
              <p className="copyright">
                <small>&copy; 2024</small>
              </p>

            </div>

            <div className="col-sm">
              <ul className="list-unstyled links">

                <li><a href={process.env.PUBLIC_URL + "/#home"}>Home</a></li>
                <li><a href={process.env.PUBLIC_URL + "/#services"}>Services</a></li>
                <li><a href={process.env.PUBLIC_URL + "/#clients"}>Clients</a></li>
                <li><a href={process.env.PUBLIC_URL + "/#references"}>Team</a></li>
                <li><a href={process.env.PUBLIC_URL + "/#testimonials"}>Testimonials</a></li>
                <li><a href={process.env.PUBLIC_URL + "/#contact"}>Get In Touch</a></li>
              </ul>
            </div>
            <div className="col-md-5">
              <div className="m-5 d-flex justify-content-center align-items-center">
                {icons.map((icon, index) => (
                  // <div className='icon-container'> 
                  <a
                    key={`social-icon-${index}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={icon.url}
                    aria-label={`My ${icon.image.split("-")[1]}`}
                    className="mx-2 icon"
                  >
                    <i className={`fab ${icon.image} fa-3x socialicons `} />
                  </a> 
                  // </div>
                ))}
              </div>
            </div>    
          </div>
        </div>
      </div>
    </footer>

  );
};

export default Footer;
