import TestimonialCard from '../cards/testemonial_card';
import { Row } from 'react-bootstrap';
const references = {
  show: true,
  heading: "References",
  data: [
//     

// Beyond his technical skills, Mohamed brought a strategic mindset to the table, aligning our data architecture with our business goals. His ability to understand our needs and deliver solutions that not only met but often exceeded our requirements was truly impressive. Mohamed also excelled in collaborating with cross-functional teams, effectively communicating complex concepts in a clear and accessible manner.

// 
{
  name: 'Moaad Dahmane',// Here Add Company Name
  role: 'Senior Data Engineer',// Here Add Company Name
  company : 'Vinci Airports',
  image: '/assets/moaad.jpg',
  text: "I had the pleasure of working with Mohamed as our Data Architect, and I can confidently say that his performance exceeded all expectations. His expertise in designing and implementing scalable data architectures was instrumental in transforming our data landscape. Mohamed demonstrated exceptional proficiency in managing complex big data environments, ensuring seamless data integration, and optimizing our data infrastructure for better performance and efficiency. Thanks to Mohamed's contributions, our data processes have become more streamlined, reliable, and scalable. His dedication to quality, attention to detail, and innovative approach to problem-solving make him an invaluable asset to any organization. I highly recommend Mohamed for any project requiring a top-tier Data Architect."

},
{
  name: 'Abdellah Addoud',// Here Add Company Name
  role: 'Manager at Finance Dep.',// Here Add Company Name
  company : 'Credit Agricole Assurance',
  image: '/assets/abdellah_addoud.jfif',
  text: "Mohamed was able to adapt quickly to the challenging context of the mission, and to develop the necessary skills for the successful completion of the mission. He was a driving force within the team, his rigor, his enthusiasm, his reliability, his availability as well as his initiative were key factors for the progress of the project in the best conditions."

},
    {
      name: 'Arnaud Nujurally',// Here Add Company Name
      role: 'Project Manager',// Here Add Company Name
      company : 'Credit Agricole Assurance',
      image: '/assets/arnaud.jpg',
      text: "Mohamed joined my teams to provide his expertise on Big Data and regulatory insurance topics. He managed to maintain a very high level of commitment. I greatly appreciated his team spirit and technical expertise, which enabled us to achieve great things. Thank you, Mohamed!"
    
    },
    {
      name: 'Mahdi Amrani',// Here Add Company Name
      role: 'Business Analyst',// Here Add Company Name
      company : 'Accenture',
      image:'/assets/mahdi amrani.jfif',
      text: "Curious and persistent, Mohamed has proven himself as a technical expert, on large scale strategic finance projects at Credit Agricole Assurance."    }
  ]
}

const Testimonials = () => {
    return (
      <section id="testimonials" style={{ backgroundColor: '#e0e0e0' }}>
        <div className="container  d-flex flex-column align-items-center">
             <h2 className="header">Testimonials</h2>
             <hr className="small-line" />


            <Row className="justify-content-md-center">
                        {
                            references.data.map((data,index) => {
                                return <TestimonialCard key={index} data={data} />
                            })
                        }
          </Row>

        </div>
      </section>
    );
  };
  export default Testimonials